import axios from "axios";

class FoldersService {
  async getFolders () {
    return await axios
      .get("api/folders")
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async getFolderMaterials (folderId) {
    return await axios
      .get(`api/folders/documents/${folderId}`)
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new FoldersService();
